import React, { useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { useHistory, Link } from 'react-router-dom';
import { Home as HomeIcon } from 'react-feather';

import { CardTitle } from 'reactstrap';

import { CommonCard } from 'components/common/CommonCard';
import { useAuth } from 'hooks';

export interface LayoutProps {
  component: JSX.Element;
}

export const Layout: React.FC<LayoutProps> = ({ component }) => {
  const { location, push } = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (user) push('/search');
  }, [user, push]);

  if (user === undefined) return null;

  const pageName = sentenceCase(String(location.pathname.split('/').pop()));

  return (
    <div className="layout">
      <div className="parts-subhead">
        <p className="text-primary">Parts & Services</p>
        <h1 className="text-white">PARTS</h1>
      </div>
      <CommonCard>
        <CardTitle>
          <h2>{pageName}</h2>
          <Link to="/">
            <HomeIcon />
          </Link>
        </CardTitle>
        {component}
      </CommonCard>
    </div>
  );
};

Layout.defaultProps = {};
