import React, { ChangeEvent, useState } from 'react';
import { Input, InputGroup } from 'reactstrap';
import { useData } from 'hooks';
import { LogOut, Home as HomeIcon, Link as LinkIcon } from 'react-feather';
import { TypeheadItem } from 'models';
import { SubNav } from 'components';

export const Search = () => {
  const { typeaheadValue, setSearchWithDelay, initSelectedValue, typeaheadResults } = useData();
  const [searchTerm, setSerchTerm] = useState('');

  const handleOnClick = async (item: TypeheadItem) => {
    initSelectedValue(item);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSerchTerm(value);
    setSearchWithDelay(value);
  };

  return (
    <div className="search">
      <SubNav
        linkLeft={{ path: '/', element: <HomeIcon /> }}
        linkRight={{ path: '/auth/sign-out', element: <LogOut /> }}
      />
      <InputGroup>
        <Input
          type="text"
          bsSize="lg"
          value={searchTerm}
          placeholder="Please enter the name of the product..."
          className="border-primary "
          onChange={handleOnChange}
        />
      </InputGroup>
      {!!searchTerm && typeaheadResults.length === 0 && (
        <div className="typeahead-results mt-2 py-2 px-3 animated fadeIn faster">
          <i>No products found!</i>
        </div>
      )}

      {typeaheadResults.length > 0 && !!typeaheadValue && (
        <div className="typeahead-results mt-2 py-2 px-3 animated fadeIn faster">
          {typeaheadResults.map(item => (
            <p key={item.productID} onClick={() => handleOnClick(item)}>
              <span>{item.value}</span>
              <span>
                <LinkIcon size="10px" />
              </span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
