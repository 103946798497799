import React from 'react';
import { useData } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Search as SearchIcon, LogOut } from 'react-feather';
import { CommonCard } from 'components/common/CommonCard';
import { SubNav } from '../SubNav';

export const Results: React.FC = () => {
  const { selectedValue } = useData();
  const { push } = useHistory();
  if (!selectedValue) {
    push('/search');
    return null;
  }

  return (
    <div className="results">
      <SubNav
        linkLeft={{ path: '/search', element: <SearchIcon /> }}
        linkRight={{ path: '/auth/sign-out', element: <LogOut /> }}
      />
      <CommonCard>
        <p>
          <b>Description:</b> {selectedValue.description}
        </p>
        <p>
          <b>Price:</b> {selectedValue.listPrice}
        </p>
        <p>
          <b>Weight:</b> {selectedValue.weight}
        </p>
        <p>
          <b>UM:</b> {selectedValue.um}
        </p>
      </CommonCard>
    </div>
  );
};
