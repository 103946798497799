import React from 'react';
import { render } from 'react-dom';

import { MemoryRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import MutationObserver from 'mutation-observer';
(global as any).MutationObserver = MutationObserver;

export const renderWithHarness = (component: React.ReactElement<any>, container: Element) => {
  const RenderElm = (
    <HelmetProvider>
      <MemoryRouter>{component}</MemoryRouter>
    </HelmetProvider>
  );

  return render(RenderElm, container);
};
