import { useEffect } from 'react';
import { useAuth } from 'hooks';
import { useHistory } from 'react-router-dom';

export const SignOut = () => {
  const { signOut } = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    const callSignOut = async () => {
      await signOut();
      push('/');
    };

    callSignOut();
  }, [push, signOut]);
  return null;
};

SignOut.defaultProps = {};
