import React, { Props } from 'react';
import { Card, CardBody } from 'reactstrap';

export interface CommonCardProps extends Props<{}> {}

export const CommonCard = ({ ...props }: CommonCardProps) => {
  return (
    <div className="commonCard">
      <Card>
        <CardBody>{props.children}</CardBody>
      </Card>
    </div>
  );
};

CommonCard.defaultProps = {};
