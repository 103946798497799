import React from 'react';
import { HomeCTA } from './HomeCTA';

export const Home: React.FC = () => {
  return (
    <div className="home">
      <header className="home-header">
        <h1 className="company-name">
          PROTO
          <br />
          <span>TYPE</span>
        </h1>
        <div className="parts-subhead">
          <p className="text-primary">Parts & Services</p>
          <h1 className="text-white">PARTS</h1>
        </div>
        <nav>
          <HomeCTA />
        </nav>
      </header>
    </div>
  );
};
