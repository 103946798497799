import React from 'react';
import { Link } from 'react-router-dom';
import { NavLinkItem } from 'models';

export interface SubNavProps {
  linkLeft: NavLinkItem;
  linkRight: NavLinkItem;
}

export const SubNav: React.FC<SubNavProps> = ({ linkLeft, linkRight }) => {
  return (
    <div className="sub-nav">
      <Link to={linkLeft.path}>{linkLeft.element}</Link>
      <Link to={linkRight.path}>{linkRight.element}</Link>
    </div>
  );
};

SubNav.defaultProps = {};
