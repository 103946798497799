import { FirebaseCollections, RangeComparison } from 'models';

import { firebaseDB } from 'config';

export const readDocumentWhere = async (
  collectionName: FirebaseCollections,
  param1: string,
  range: RangeComparison,
  param2: string,
) => {
  const cacheValue = localStorage.getItem('today');
  const today = new Date().getUTCDay();

  const collectionRef = firebaseDB.collection(collectionName).where(param1, range, param2);
  let snapshot = await collectionRef.get({ source: 'cache' });
  if (snapshot.docs.length === 0) {
    snapshot = await collectionRef.get();
  }
  if (cacheValue !== today.toString()) {
    localStorage.setItem('today', today.toString());
    snapshot = await collectionRef.get();
  }

  const doc = snapshot.docs[snapshot.docs.length - 1];
  if (doc) {
    return snapshot.docs[snapshot.docs.length - 1].data();
  }

  return false;
};
