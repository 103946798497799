// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

// TODO: Add Key Secrets to .env file
const firebaseConfig = {
  apiKey: 'AIzaSyDOIocqiwSEEGfheGhOzQEbi4Yz4KG2osY',
  authDomain: 'jaggeweerakkuraat-1265.firebaseapp.com',
  databaseURL: 'https://jaggeweerakkuraat-1265.firebaseio.com',
  projectId: 'jaggeweerakkuraat-1265',
  storageBucket: 'jaggeweerakkuraat-1265.appspot.com',
  messagingSenderId: '362968587594',
  appId: '1:362968587594:web:1f985b7a1752e5c068b5b3',
};

// Initialize Firebase
export const firebaseInit = firebase.initializeApp(firebaseConfig);

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

// Enable offline support
firebase
  .firestore()
  .enablePersistence()
  .catch(error => {
    if (error.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
    }
  });

export const firebaseAuth = firebaseInit.auth();
export const firebaseDB = firebase.firestore();
