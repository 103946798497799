import React, { useState, useContext, createContext, useEffect, useMemo } from 'react';
import { firebaseDB } from 'config';
import { AppDataContext, TypeheadItem, Product } from 'models';
import { readDocumentWhere } from 'utils';
import { useHistory } from 'react-router-dom';
import { debounce } from 'debounce';
import { useAuth } from './use-auth';

const DataContext = createContext({} as AppDataContext);

export const ProvideData: React.FC = ({ children }) => {
  const data = useProvideData() as any;
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export const useData = () => {
  return useContext(DataContext);
};

const useProvideData = () => {
  const [typeaheadValues, setTypeaheadValues] = useState<TypeheadItem[]>();
  const [typeaheadValue, setTypeaheadValue] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<Product | null>(null);
  const { user } = useAuth();
  const { push } = useHistory();

  const setSearchWithDelay = debounce(setTypeaheadValue, 1000);

  const typeaheadResults = useMemo(() => {
    if (!typeaheadValues) return [];
    const resutls = typeaheadValues?.filter((record, index: number) => {
      const regex = new RegExp(typeaheadValue, 'gi');
      return record.value.match(regex);
    });

    return resutls.slice(0, 20);
  }, [typeaheadValue, typeaheadValues]);

  const initSelectedValue = async (typeaheadItem: TypeheadItem) => {
    if (!typeaheadItem) return;

    try {
      const currentProduct = (await readDocumentWhere(
        'products',
        'id',
        '==',
        typeaheadItem.productID,
      )) as Product;

      setSelectedValue(currentProduct);
      setTypeaheadValue('');
      push('/result');
    } catch (error) {
      console.log(error, 'UseData, initSelectedValue fucked.');
    }
  };

  useEffect(() => {
    const getTypeaheadValues = async () => {
      const typeahead: TypeheadItem[] = [];
      try {
        const typeaheadRef = firebaseDB.collection('typeahead');
        const typeaheadSnap = await typeaheadRef.get();

        typeaheadSnap.forEach(doc => {
          typeahead.push(doc.data() as any);
        });

        setTypeaheadValues(typeahead);
      } catch (error) {
        console.log(error, 'UseData, use effect fucked.');
      }
    };

    if ((user && !typeaheadValues) || typeaheadValues?.length === 0) {
      getTypeaheadValues();
    }
  }, [user, typeaheadValues]);

  return {
    typeaheadResults,
    typeaheadValues,
    initSelectedValue,
    selectedValue,
    typeaheadValue,
    setSearchWithDelay,
  };
};

/**
 * TODO:
 * - When an item is selected only fetch that one item and add to selected state for display
 */
