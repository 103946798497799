import 'assets/scss/index.scss';
import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { SignIn, SignUp, Layout as AuthLayout, ProtectedLayout, Search, Results } from 'components';
import { Home } from 'components/common/Home';
import { ProvideAuth, ProvideData } from 'hooks';
import { SignOut } from 'components/auth/SignOut';

export const App: React.FC = () => {
  return (
    <div className="app">
      <Router>
        <ProvideAuth>
          <ProvideData>
            <div className="content animated faster fadeIn">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/search">
                  <ProtectedLayout component={<Search />} />
                </Route>
                <Route exact path="/result">
                  <ProtectedLayout component={<Results />} />
                </Route>
                <Route exact path="/auth/sign-in">
                  <AuthLayout component={<SignIn />} />
                </Route>
                <Route exact path="/auth/sign-up">
                  <AuthLayout component={<SignUp />} />
                </Route>
                <Route exact path="/auth/sign-out">
                  <AuthLayout component={<SignOut />} />
                </Route>
                <Route path="*">
                  <Home />
                </Route>
              </Switch>
            </div>
          </ProvideData>
        </ProvideAuth>
      </Router>
    </div>
  );
};
