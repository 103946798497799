import React from 'react';
import { Link } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import { useForm } from 'react-hook-form';

import { Form, FormGroup, Label, Input, FormFeedback, Button, Spinner } from 'reactstrap';

import { User } from 'models';
import { useAuth } from 'hooks';

export const SignIn: React.FC = () => {
  const { register, handleSubmit, errors } = useForm();
  const { signIn, isLoading } = useAuth();

  const onSubmit = async (data: Partial<User>) => {
    await signIn(data);
  };

  const inputFeeback = (formErrors: any) => (
    <FormFeedback>{formErrors && formErrors.message}</FormFeedback>
  );
  const inputError = (inputName: string) => `${sentenceCase(inputName)} is required`;

  return (
    <div className="sign-in">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            name="email"
            placeholder="Enter your email"
            type="text"
            innerRef={register({ required: inputError('email') })}
            invalid={!!errors.email}
          />
          {inputFeeback(errors.email)}
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            name="password"
            placeholder="Enter your password"
            type="password"
            innerRef={register({ required: inputError('password') })}
            invalid={!!errors.password}
          />
          {inputFeeback(errors.password)}
        </FormGroup>
        <div className="sign-in-actions">
          <Button type="submit" color="primary">
            {isLoading ? <Spinner type="grow" color="white" size="sm" /> : 'Sign in'}
          </Button>
          <Link to="/auth/sign-up" className="small">
            Sign up
          </Link>
        </div>
      </Form>
    </div>
  );
};
