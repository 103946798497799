import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks';

export interface ProtectedLayoutProps {
  component: JSX.Element;
}

export const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ component }) => {
  const { push } = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) push('/auth/sign-in');
  }, [user, push]);

  if (!user) return null;

  return (
    <div className="protected-layout">
      <div className="parts-subhead">
        <p className="text-primary">Parts & Services</p>
        <h1 className="text-white">PARTS</h1>
      </div>
      {component}
    </div>
  );
};
