import React, { useState, useEffect, useContext, createContext } from 'react';

import { User, AppAuthContext, FirebaseUser } from 'models';
import { firebaseAuth } from 'config';
import { burnToast } from 'utils';

const AuthContext = createContext({} as AppAuthContext);

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth() as AppAuthContext;
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState<FirebaseUser | null>();
  const [isLoading, setIsLoading] = useState(false);

  const signIn = async ({ email, password }: User) => {
    setIsLoading(true);

    try {
      const response = await firebaseAuth.signInWithEmailAndPassword(email, password);
      setUser(response.user);

      burnToast('success', `Welcome back, ${response.user?.email}`);

      return response.user;
    } catch (error) {
      burnToast('error', `Oops, ${error.message}`);

      return console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async ({ email, password }: User) => {
    setIsLoading(true);
    try {
      const response = await firebaseAuth.createUserWithEmailAndPassword(email, password);
      setUser(response.user);

      burnToast('success', `Welcome, ${response.user?.email}`);

      return response.user;
    } catch (error) {
      burnToast('error', `Oops, ${error.message}`);

      return console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    setIsLoading(true);
    try {
      await firebaseAuth.signOut();
      setUser(false);
      burnToast('success', `Cheers... till next time!`);
    } catch (error) {
      burnToast('error', `Oops, ${error.message}`);
      return console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const forgotPassword = async ({ email }: User) => {
  //   setIsLoading(true);
  //   try {
  //     await firebaseAuth.sendPasswordResetEmail(email);
  //     return true;
  //   } catch (error) {
  //     return console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const resetPassword = async ({ code, password }: User) => {
  //   setIsLoading(true);
  //   try {
  //     await firebaseAuth.confirmPasswordReset(code, password);
  //     return true;
  //   } catch (error) {
  //     return console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signUp,
    signOut,
    // forgotPassword,
    // resetPassword,
    isLoading,
  };
};
