import React from 'react';
import { Spinner, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks';

export const HomeCTA: React.FC = () => {
  const { push } = useHistory();
  const { user, isLoading } = useAuth();

  const handleClick = (path: string) => push(path);

  if (isLoading) return <Spinner type="grow" color="primary" />;

  if (!user) {
    return (
      <Button color="primary" onClick={() => handleClick('/auth/sign-in')}>
        Sign in
      </Button>
    );
  }

  return (
    <Button color="primary" onClick={() => handleClick('/search')}>
      Search
    </Button>
  );
};
