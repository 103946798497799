import React, { Props, useRef } from 'react';
import { Link } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import { useForm } from 'react-hook-form';

import { Form, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap';

import { User } from 'models';
import { useAuth } from 'hooks';

export interface SignUpProps extends Props<{}> {}

export const SignUp = ({ ...props }: SignUpProps) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const auth = useAuth();

  const password = useRef({}) as any;
  password.current = watch('password', '');

  const onSubmit = async (data: Partial<User>) => {
    await auth.signUp(data);
  };

  const inputFeeback = (formErrors?: any) => (
    <FormFeedback>{formErrors && formErrors.message}</FormFeedback>
  );
  const inputError = (inputName: string) => `${sentenceCase(inputName)} is required`;

  return (
    <div className="sign-up">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            name="email"
            placeholder="Enter your email"
            type="email"
            innerRef={register({ required: inputError('email') })}
            invalid={!!errors.email}
          />
          {inputFeeback(errors.email)}
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            name="password"
            placeholder="Enter your password"
            type="password"
            innerRef={register({ required: inputError('password') })}
            invalid={!!errors.password}
          />
          {inputFeeback(errors.password)}
        </FormGroup>
        <FormGroup>
          <Label for="confirmPassword">Confirm Password</Label>
          <Input
            name="confirmPassword"
            placeholder="Confirm your password"
            type="password"
            innerRef={register({
              required: inputError('confirmPassword'),
              validate: value => value === password.current || 'Passwords do not match!',
            })}
            invalid={!!errors.confirmPassword}
          />
          {inputFeeback(errors.confirmPassword)}
        </FormGroup>
        <div className="sign-up-actions">
          <Button type="submit" color="primary" className="text-right">
            Sign up
          </Button>
          <Link to="/auth/sign-in" className="small">
            Sign in
          </Link>
        </div>
      </Form>
    </div>
  );
};
